

import { IonContent, IonFooter, IonPage, IonTitle, IonToolbar, IonButton } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonFooter,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton
  },
  setup() {

    let message = ref('');

    const trigger = async () => {
      
      message.value = 'Please Wait.';
      try {
        const response = await axios.get('/api/trigger');
        if (response['data'] != undefined && response.data['trigger'] != undefined) {
          if (response.data.trigger == true) {
            message.value = 'Gate Triggered OK.';
          } else {
            message.value = 'Sorry, there was a problem.';
          }
        }
        console.log(response);
      } catch (e) {
        message.value = 'Sorry, there was a problem.';
      }

    }

    return {
      message,
      trigger
    };

  }
});

